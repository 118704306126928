/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 767px) {

/* global
----------------------------------------------------------------------------------------------*/
.btn-plus {
	height: 40px; line-height: 40px; width: 40px;
	span {height: 40px; line-height: 42px;}
}
.btn-block {
	height: 40px; line-height: 41px; font-size: 14px;
	&.download-btn:before {height: 40px;}
}
.btn-border {height: 40px; line-height: 38px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
	height: 60px;
	.logo {margin-left: 60px; width: 115px;}
	.right {
		a {
			font-size: 0 !important;
			&.btn {
				width: 50px; 
				line-height: 60px; 
				height: 60px; 
				&:after{
					left: 12px; 
				}
			}
			&.phone, &.online-payment {
				margin: 18px 15px 0 0;
			}
		}
    .account-nav {
      .drop {
        left: -135px; 
        right: 0;
        width: 200px;
      }
    }
	}
	&:after {left: 50px;}		
	.header-right {
		.header-top {
			.right {
				display: flex; 
				a {
					&.phone {
						display: none;
					}
				}
				.phone-mobile {
					display: inline-block; 
					position: relative ;
					margin: 18px 15px 0 0; 
					.icon {
						display: block;
						width: 26px;
						height: 26px;
						background: url('../images/material/phone-ico.png') no-repeat left center;
						background-size: 20px;
					}
					.dropdown-phone {
						position: absolute; 
						top: 100%;
						left: 50%; 
						width: 240px;
						background: #FFFFFF; 
						box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
						border-radius: 0px 0px 12px 12px;
						transform: translateX(-50%); 
						margin-left: -30px;
						padding: 4px 16px 20px; 
						display: none; 
						a {
							border-bottom: 1px solid rgba(0, 0, 0, 0.1);							 
							&.phone {
								display: block;
								color: #666;
								font-size: 14px !important;
								padding: 16px 0 16px 28px; 
								margin: 0; 
								background-position: 0 18px;
							}
							&:last-child {
								border-bottom: 0; 
								padding-bottom: 0; 
							}
						} 
					} 
				}
			}
		}
	}
}
.opt-checkbox{
	&.md{
		.item{
			label{padding: 8px 25px;}
		}
	}
}
.burger-menu {
	width: 30px; top: 20px; left: 10px; height: 21px;
	&.active span:nth-child(1) {top: 6px;}
}
.side-menu {
	padding: 60px 35px 15px; width: 320px;
	.wrap_nm {padding-top: 30px;}
	nav {
		a {font-size: 18px; margin-bottom: 15px;}
	}
	.search {
		margin-top: 30px;
		input {font-size: 14px; background-size: 16px; background-position: right center;}
	}
	.lang {margin-top: 25px;}
}

/* homepage
----------------------------------------------------------------------------------------------*/
.banner-home {
	margin-top: 60px; padding-left: 0; background: #fff; overflow: hidden;
	&.banner-std {
		margin-top: 60px; 
	}
	.slick-dots {display: none;}
	a.scroll-down {display: none;}
	.caption {
		width: auto; height: auto; max-width: 340px;
		h1 {font-size: 28px; line-height: 34px; margin-bottom: 15px;}
		a {height: 40px; line-height: 40px; font-size: 14px;}
		p {line-height: 22px;}
	}
	&.banner-std {
		.location-right {position: relative; width: auto; padding: 30px 20px; display: none;}
		.map-location {
			padding-right: 0; height: 420px;
			> div {height: 420px;} 
		}
		.caption {
			bottom: 25px; top: auto; height: auto; top: auto;
			h1 {margin-bottom: 15px; font-size: 28px; line-height: 30px; margin-bottom: 0;}
			p {display: block; margin-bottom: 0; display: none;}
		}
	}
	&.location {height: 420px;}
}

.home-sec {
	.wrapper {width: auto; margin: 0 20px;}
	p {font-size: 14px; line-height: 22px;}
	&.h-about {
		background: #fff !important;padding: 30px 0;
		.left, .right {float: none;  display: block; width: auto;}
		.left {
			h2 {
				text-align: left; font-size: 23px;
				br {display: none;}
			}
		}
		.list-col {
			.col {
				width: 46%; margin-right: 8%;
				h5 {font-size: 16px;}
			}
		}
	}
	&.h-prod {
		.wrapper {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex; display: flex; flex-flow: column; -webkit-flex-flow: column; -moz-flex-flow: column;
        -ms-flex-flow: column; -o-flex-flow: column; width: auto; margin: 0 20px;}
		.slider {
			order: 2; -webkit-order: 2; -moz-order: 2; display: block; width: 340px; float: none; margin: 50px auto 0;
			.box {
				margin: 0;
				> img {width: 100%;}
				&:hover {
					p {height: 65px;}
				}
			}
			.text {
				.btn {left: -27%;}
			}
		}
		.right {
			order: 1; -webkit-order: 1; -moz-order: 1;display: block; float: none; width: auto; padding-top: 0;
			p {max-height: none;}
		}
	}
	&.h-updates {
		padding: 30px 0;
		.list {
			.box {
				float: none; width: auto; display: block; margin-bottom: 30px;
				h6 {padding-bottom: 15px; border-bottom: 1px dotted #ccc; margin-bottom: 10px; font-size: 18px; height: auto;}
				small {display: none;}
				p {padding-top: 0; border: none;}
			}
		}
	}
	&.h-testi {
		.slider {margin-top: 40px;}
		.box {min-height: 0; margin: 0;}
		.slick-dots {top: -25px;}
	}
}


.box-form {
	label {font-size: 14px; margin-bottom: 5px;}
	.row {margin-bottom: 30px;}
	input[type=text], input[type=password], input[type=number], input[type=email], select, 
	textarea, .select2-container--default .select2-selection--multiple { font-size: 14px;}
	.input_file {
		span {font-size: 14px;}
	}
}
/* middle
----------------------------------------------------------------------------------------------*/
.content-std {
	padding: 35px 0 80px;
	h2,h3 {font-size: 23px; line-height: 30px;}
	ul {
		li:after {top: 7px;}
	}
	.wrapper {width: auto; margin: 0 20px;}
	p {
		font-size: 14px; line-height: 22px;
		&.sub {font-size: 16px; line-height: 24px;}
	}
	.date-list{margin-bottom: 10px;font-size: 13px;line-height: 16px;max-height: 16px;}
	&.faq {padding-top: 25px;}
	&.about {
		padding-top: 35px; padding-bottom: 80px;
		h2,h3 {font-size: 23px; line-height: 30px;}
		.list-col {
			.col {
				h5 {font-size: 16px;}
			}
		}
	}
	.list-prod {
		margin-top: 40px;
		.box {
			.text {padding-bottom: 20px;}
			h5 {font-size: 23px;line-height: 28px;}
			h4 {font-size: 18px; margin-bottom: 20px; line-height: 22px;}
			h6 {font-size: 18px; margin-bottom: 12px;}
			p { max-height: 66px; overflow: hidden;text-overflow: ellipsis; display: block; -webkit-line-clamp: 3; -webkit-box-orient: vertical;}
		}
		&.news .box {
			h4 {height: 66px;}
			.btn-plus {margin-bottom: -40px;}
		}
	}
	&.content-detail {
		.wrapper {width: auto; margin: 0 20px;}
		ul,ol,li {font-size: 14px; line-height: 24px;}
		.image-slider,.image-content  {
			.slick-dots {bottom: 5px;}
		}
		.arr_drop {
			display: block; padding: 10px 15px 8px; border: 1px solid #333; font-size: 18px; color: #333; position: relative; font-weight: 600; line-height: 20px;
			&:after {
				content:''; border-top: 6px  solid #333; border-left: 6px  solid transparent; border-right: 6px  solid transparent; width: 0;
				display: block; position: absolute; right: 20px; top: 16px;
			}
			+ .nav-sub {
				border: 1px solid #333; border-top: 0; display: none;
				a {display: block; text-align: left; padding: 12px 15px; font-size: 16px;}
				> span {display: none;}
				+ .image-content {margin-top: 40px;}
			}
		}
		.list-gallery {margin-top: 40px;}
		.box-inquiry {display: none;}
	}
	.service-room-list {
		.box {
			.img {
				max-width: 320px; margin: 0 0 30px; float: none; display: block; width: auto;
				h5 {font-size: 26px;}
				small {font-size: 18px;}
			}
			.text {float: none; display: block; width: auto; margin-left: 0;}
			.wrap-btn {float: none; display: block;  width: auto; margin-left: 0;}
		}
	}
	&.buy-step {
		.wrapper {width: auto; margin: 0 20px;}
		.wrap-step {
			.box {
				.ico {
					width: 50px; height: 50px;
					span {width: 50px; height: 50px; background-size: 50px !important;}
				}
				h6 {display: none;}
				&:after {height: 5px; top: 22px;}
			}
		}
		.box-form {
			width: auto;
			.col {
				width: 46%; margin-right: 8%;
				&:nth-of-type(2) {margin-right: 0;}
			}
		}
		.left-content {
			h3 {
				line-height: 30px;
				&:after {height: auto; bottom: 0;}
			}
			.box-form {
				width: auto;
				.row {
					&.col-3 {
						.col {
							width: 45%; margin-right: 10%; margin-bottom: 25px;
							&:nth-child(2) {margin-right: 0;}
							&:nth-child(3) {margin-bottom: 0;}
						}
					}
				}
			}
		}
		.right-content {
			&.fixedwp {
				top: 60px;
			}
		}
		.box-red {
			.box {
				padding: 25px 15px;
				h6 {margin-bottom: 10px;}
				.left {
					font-size: 16px;
					small {font-size: 12px;}
				}
			}
			&.grey-type {
				margin-top: 15px;
			}
		}
		.btn-next {margin-top: 35px;}
		.payment-option {
			margin-bottom: 60px;
			a {
				width: 48%; margin-right: 4%; @include boxSizing(border-box);
				&:nth-child(2n) {margin-right: 0;}
			}
		}
		.payment-detail {
			.box-inner {
				margin: 30px 0  20px 20px;
				p {font-size: 16px; line-height: 22px; margin-bottom: 15px;}
				table {
					td {font-size: 16px;}
				}
			}
		}
	}
	.counter-num {margin: auto;}
	.tbl-std {
		thead {display: none;}
		tbody {
			tr {
				td {
					display: block; padding: 0 20px 15px !important; text-align: center; border-right-color: #ccc; font-size: 16px; border-bottom: none;
					&:before {
						content:attr(data-title); display: block; position: relative; display: block; padding: 10px 20px; background: #e1e1e1;
						font-size: 16px; margin: 0 -20px 20px; font-weight: 600; color: #333;
					}
					&:first-child {
						max-width: none;
						&:after {display: none;}
					}
					&:last-child {
						padding: 0 !important; margin-bottom: 35px; border-top: none;
						&:before {display: none;}
					}
					.delete {text-indent: 0; background: #9f9f9f; text-transform: capitalize;font-size: 16px; display: block; width: auto; color: #fff; height: 40px; line-height: 40px; }
					i {padding-left: 0;}
					p {margin-bottom: 0;}
					
				}
				&.space {display: none;}
			}
		}
		&.table-history {
			tbody {
				td {
					display: inline-block; width: 50%; @include boxSizing(border-box); vertical-align: top; font-size: 14px; padding: 0 15px 15px !important;
					&:before {font-size: 15px ;margin: 0 -15px 15px;}
					&:nth-child(6),&:nth-child(5) {min-height: 94px;}
					&:last-child {width: 100%; border: none;}
				}
				.btn-block {width: 100%;}
			}
		}
	}
	&.content-detail {
		.title {
			small.tgl {margin-bottom: 20px; text-align: left;}
			&.little {margin: 10px 0 20px;}
			h4 {font-size: 18px; line-height: 24px;}
		}
	}
	.list-location {
		.box {
			.img {float: none; width: auto; height: auto;}
			.text {
				float: none; width: auto; display: block; height: auto; padding: 30px 20px 15px;
				h4 {font-size: 23px;}
				p {
					height: 68px;
					&.sub {height: auto; margin-bottom: 10px;}
				}
			}
		}
	}
	.box-contact {
		.left {
			h4 {font-size: 23px;}
			p {font-size: 14px; line-height: 22px;}
		}
	}
	.price-type {
		margin-bottom: 30px;
		.box {
			width: 200px; margin-bottom: 20px;
			h5 {margin-top: 5px; font-size: 17px;}
			.head {
				height: 80px; padding: 22px 10px 0 18px; margin-bottom: 20px;
				h4 {font-size: 18px;}
				small {font-size: 10px;}
			}
			.price-box {margin-bottom: 15px;}
			.foot {padding: 20px 10px;}
		}
	}
	.accordion {
		ul, ol {
			li {font-size: 16px;}
		}
		p {font-size: 16px;}
		.h-acc {
			padding: 20px 70px 20px 20px;
			h5 {font-size: 18px;}
			small {font-size: 14px;}
		}
		.c-acc {
			padding: 20px 25px;
		}
	} 
	&.account-page {
		.wrapper {margin: 90px 20px 0px;}
	}
	.box-register {
		margin-top: 70px;
		.right {
			padding: 50px 30px;
			.row-btn {margin-top: 35px;}
		}
	}
}

.thanks-page {
	width: auto; margin: 200px 20px 100px;
	.ico {
		width: 120px; height: 120px; margin-top: -65px;
		.ico-1 {width: 90px; top: -10px; right: -50px;}
		.ico-2 {width: 62%;}
	}
	.text {
		padding: 40px 30px;
		h5 {font-size: 23px; margin-bottom: 20px;}
		p {font-size: 14px; line-height: 22px;}
		.wrap-btn {margin-top: 30px;}
	}
}

.pg_404 {
	width: auto; margin: 100px 20px 50px;
	.ico {width: 150px; margin: auto;}
	.text {
		width: auto;
		h5 {font-size: 23px; line-height: 30px; margin: 20px 0 20px;}
		p {font-size: 16px; line-height: 22px;}
		.bk_index {font-size: 16px; margin-top: 25px;}
	}
}


.src_result {width: auto; margin: 100px 20px 80px;}
.src_form {
	margin-bottom: 30px;
	form {
		input[type="text"] {font-size: 14px;}
		input[type="submit"] {width: 50px;}
	}
}
.wrap_src {
	width: auto;
	h2 {font-size: 23px; line-height: 30px;}
	h6 {font-size: 16px; margin-bottom: 40px;}
	.list_src {
		.item {
			padding-bottom: 30px; margin-bottom: 30px;
			a {
				h4 {font-size: 18px;}
				p {font-size: 14px;}
			}
		}
	}
}
.sig-lst{
	.ls-sig-lst{width: 100%;}
}

.pagging {
	a {
		font-size: 14px; padding: 0 6px 4px;
		&.prev,
		&.next {width: 16px; height: 16px; background-size: 32px;}
	}
}


.box-signature .textsig span {font-size: 32px;}

.button-group {
  flex-direction: column;
  gap: 5px 0;
}



/* popup
----------------------------------------------------------------------------------------------*/


.wrap-popup {
	.box-popup {
		margin: 10% 30px; width: auto;
		h4 {font-size: 20px; margin-bottom: 20px; line-height: 24px;}
		.arrow-nav {
			width: 14px; background-size: 28px;
			&.next {right: -22px;}
			&.prev {left: -22px;}
		}
		a.close {width: 35px; height: 35px; background-size: 15px; top: -15px; right: -15px;}
	}
}


/* footer
----------------------------------------------------------------------------------------------*/
footer {
	height: auto; padding-bottom: 15px;
	.wrapper {width: auto; margin: 0 20px;}
	.left {
		nav {
			a {margin-bottom: 10px; display: inline-block;}
		}
	}
}

} /* end media */



@media all and (max-width: 639px) {

/* global
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/


/* homepage
----------------------------------------------------------------------------------------------*/
.banner-home {
	.wrap-caption {margin-left: 25px;}
	.caption {
		h1,h2 {margin-bottom: 30px;}
		p {display: none;}
	}
	&.banner-std {
		caption {
			p {display: block;}
		}
	}
}
.home-sec {
	&.h-about {
		.list-col {
			.col {
				width: auto; margin-right: 0; float: none; display: block;
			}
		}
	}
	&.h-contact {
		.box-form {
			.row {
				margin: 0 0 25px;
				.col {width: auto; display: block; margin-right: 0; float: none; margin-bottom: 25px;}
			}
		}
	}
}

/* middle
----------------------------------------------------------------------------------------------*/

.content-std {
	&.about {
		.list-col {
			.col {display: block; width: auto; margin-right: 0; float: none; margin-bottom: 30px;}
		}
	}
	.service-room-list {
		.box {
			.wrap-btn {
				a{display: block !important; margin: 0 auto 10px !important;}
			}
		}
	}
	&.buy-step {
		.left-content {
			.box-form {
				width: auto;
				.row {
					&.col-3 {
						.col {
							width: 100%; margin-right: 0; margin-bottom: 30px;
							&:nth-child(2) {margin-right: 0;}
							&:nth-child(3) {margin-bottom: 0;}
						}
					}
					.col {width: 100%; float: none; margin-right: 0; margin-bottom: 30px;}
				}
			}
		}
		.btn-next {
			margin-top: 35px;
			a, .btn-block {display: block; margin: 0 auto 20px !important; width: 100%;}
		}
	}
	.box-contact {
		.box-form {
			.row {
				.col { width: 100%; float: none; display: block; margin-bottom: 25px;}
			}
		}
	}
	.wrap_tbl {
		width: auto; overflow: hidden;
		.std-table {
			&.facility {
				width: 100%; margin-bottom: 35px !important;
				thead {display: none;}
				tbody {
					td {
						display: block; width: 100% !important; text-align: left; @include boxSizing(border-box); white-space: normal;
						padding: 15px;
						span {display: block; margin-left: 200px;}
						&:before {
							content: attr(data-title); display: inline-block; float: left; width: 200px;
						}
						&:after {clear: both; display: block; content: '';}
						&:first-child {
							background: #333; color: #fff; padding-left: 20px;
							&:before {display: none;}
							span {float:none; margin-left: 0; line-height: 16px;}
						}
						&:last-child {margin-bottom: 25px;}
					}
				}
				&.add_span {
					tbody {
						td {
							&:before {width: 130px;}
							span {font-size: 16px; margin-left: 148px;}
							&:first-child{
								span {margin-left: 0;}
							}
						}
					}
				}
			}
		}
	}
	.support-facility {
		.std-table {
			thead {
				display: none;
			}
			tbody {
				td {
					display: block; position: relative; text-align: left; padding: 15px !important; font-size: 14px; line-height: 18px;
					&:before {
						content: attr(data-title); display: block; position: relative; font-size: 14px; font-weight: 600;
						float: left; width: 130px;
					}
					&:after {
						content: ' '; display: block; clear: both;
					}
					&:last-child {margin-bottom: 30px;}
					span {
						display: block; margin-left: 135px; position: relative; padding-left: 3px;
						&:before {
							content: ':'; position: absolute; top: 0; left: -5px; display: inline-block;
						}
					}
				}
			}
		}
		.right {
			padding: 30px 20px; width: auto;
		}
	}
	&.account-page {
		.box-form {
			.row {
				.col {float: none; display: block;  width: 100%; margin-bottom: 20px;}
			}
		}
	}
	&.content-detail {
		.list-gallery {
			.box {width: auto; display: block; float: none; margin: 0 0 20px;}
		}
	}
	.share-list{margin-bottom: 15px;}
	
}
.opt-checkbox{
	&.md{
		.item{
			float: none; margin-right: 0; margin-bottom: 30px;

		}
	}
}
.tagg {
	padding-left: 55px;
	.lbl {font-size: 14px; top: 10px;}
	ul {
		li {
			margin: 0 3px 4px;
			a {font-size: 14px;}
		}
	}
}



/* popup
----------------------------------------------------------------------------------------------*/



/* footer
----------------------------------------------------------------------------------------------*/



} /* end media */



@media all and (max-width: 479px) {

/* global
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/
header {
	.right {
		.account-nav {
			> a {
				font-size: 0px !important; padding:  0 20px;
        margin-top: 0;
				&:after {display: none;}
			}			
		}
		a.phone {padding-left: 34px;}
	}
}

/* homepage
----------------------------------------------------------------------------------------------*/
.home-sec {
	&.h-prod {
		.slider {width: auto; margin: 40px 15px 0;}
	}
}
.box-form {
	.g-recaptcha {@include transform(scale(0.85));position: relative; margin-left: -22px;}
}

/* middle
----------------------------------------------------------------------------------------------*/
.banner-home {
	.caption {max-width: none; right: 20px;}
}
.content-std {
	.list-prod {
		.box {
			float: none; display: block; width: auto; margin-right: 0;
			&:nth-of-type(3n) {margin-right: 0;}
			p {height: auto;}
		}
		&.news {
			.box {
				h4 {height: auto;}
			}
		}
	}
	&.buy-step {
		.box-form {
			width: auto;
			.col {
				width: auto; margin-right: 0; float: none; display: block; margin-bottom: 30px;
				&:nth-of-type(2) {margin-right: 0;}
			}
		}
		.wrap-step {
			.box {
				.ico {
					width: 42px; height: 42px;
					span {width: 42px; height: 42px; background-size: 42px !important;}
				}
				&:after {top: 19px;}
			}
		}
		.box-red {
			.box {
				.left,.right {width: auto; display: block; float: none; text-align: left;}
				.left {
					font-size: 14px;
					small {margin-top: 10px;}
				}
				.right {
					margin-top: 20px; font-size: 18px;
					small {font-size: 12px;}
				}
			}
		}
		.payment-option {
			a {
				width: auto; margin-right:0;  display: block; float: none; margin-bottom: 20px; height: 160px;
				&:nth-child(2n) {margin-right: 0;}
				span.ico {width: 80px; height: 54px; background-size: 81px !important; top: 35px;}
				span.text {font-size: 16px; bottom: 34px;}
			}
		}
	}
}
.tagg {
	padding-left: 0px;
	.lbl {font-size: 14px; top: 0; position: relative; margin-bottom: 10px; display: block;}
	ul {
		li {
			
		}
	}
}



.ui-datepicker {width: 21em;}
/* popup
----------------------------------------------------------------------------------------------*/



/* footer
----------------------------------------------------------------------------------------------*/



} /* end media */

