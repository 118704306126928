/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon";
 
@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin transform($attr){
    transform: $attr;
    -webkit-transform: $attr;
    -moz-transform: $attr;
    -ms-transform: $attr;
    -o-transform: $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin boxSizing($attr){
    box-sizing:  $attr;
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    -ms-box-sizing: $attr;
    -o-box-sizing: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin clearfix{
    &:before,
    &:after{
        content: " "; 
        display: table; 
    }
    &:after{
        display: block; 
        clear: both;
        height: 0px;
        margin-top: -1px;
        visibility: hidden;
    }
    &{
        *zoom: 1;
    }
}
.absolute-center{position: absolute;top:0;bottom:0;right:0;left:0;margin:auto;}
.no-margin{margin: 0 !important;}
.after_clear{content: "";display: block;clear: both;}
.a-right{text-align: right}
.a-left{text-align: left}

$ease: cubic-bezier(0.065, 0.840, 0.260, 0.990);
/* color global
----------------------------------------------------------------------------------------------*/
$red :#d31f22;
$white :#fff;
$orange:#ff7200;
$c_111: #111;
$c_333: #333;
$c_666: #666;
$c_999: #999;
$c_ea: #eaeaea;

$grabvibes:'Great Vibes', cursive;
$pacifito:'Pacifito', cursive;
$homemade:'Yellowtail', cursive;
$tangerine: 'Tangerine', cursive;
$nothing: 'Nothing You Could Do', cursive;
$damion: 'Damion', cursive;
$parisienne: 'Parisienne', cursive;
$italianno: 'Italianno', cursive;
$labelle: 'La Belle Aurore', cursive;
$vampiroone: 'Vampiro One', cursive;
$aguafina: 'Aguafina Script', cursive;
$league: 'League Script', cursive;
$molle: 'Molle', cursive;
$alex: 'Alex Brush', cursive;
$pinyon: 'Pinyon Script', cursive;
